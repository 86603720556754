 

export default class LocalStorageManager {

    static add(objectName = "ObjectName", object = {}) {
        localStorage.setItem(objectName, JSON.stringify(object));
    }

    static get(objectName = "ObjectName") {
        return JSON.parse(localStorage.getItem(objectName));
    }

    static set(objectName = "ObjectName", object = {}) {
        localStorage.setItem(objectName, JSON.stringify(object));
    }

    /**
     * 
     * @param {String} objectName | class name
     * @returns {boolean}
     */
    static isSet(objectName = "ObjectName") {
        return localStorage.getItem(objectName) ? true : false;
    }

    /**
     * 
     * @param {string} objectName 
     * @returns {Object[]}
     */
    static getAll(objectName = "ObjectName", onNull = []) {
        return JSON.parse(localStorage.getItem(objectName)) || onNull;
    }

    static addAll(objectName = "ObjectName",objects = []) {
        if(objects.length > 0) {
            localStorage.setItem(objectName, JSON.stringify(objects));
        }
    }
}