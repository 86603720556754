export default class User {
  static _NAME = "User";

  constructor(obj) {
    this.id = obj.id;
    this.firstName = obj.firstName;
    this.lastName = obj.lastName;
    this.email = obj.email;
    this.birthDay = obj.birthDay;
  }
}