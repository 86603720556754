import React from "react";
import Constants from "../../../../Constants";
import AuthLoginToken from "../../../../Model/AuthLoginToken";
import WebinifyApp from "../../../../Model/WebinifyApp";
import AuthController from "../../../../Security/AuthController";
import AuthorizeController from "../ServiceProvider/AuthorizeController";

const {REACT_APP_NAME} = process.env;

export default class SingleSignOnController extends React.Component {
  static _PATH = "/auth/sso/v1/login";
  constructor(props) {
    super(props);

    this.generateAndRedirect = this.generateAndRedirect.bind(this);
  }

  componentDidMount() {
    // this.generateAndRedirect();
    this.redirectControl();
  }

  async redirectControl() {
    // get from localstorage any previsuoly stored auth data
    const authTokens = AuthLoginToken.fromStorage();
     // if no auth data, redirect to main page for login for;
     if (!authTokens) {
      // im not logged in
      // redirect me login page
      // and forward all query params
      this.redirectToLogin();
    } else{
        // if loggedin 
       if(await AuthController.isLoggedIn() === true) {
        // the user is logged in
        // redirect to app and log it
        await this.redirectToApp(authTokens);
      } else {
        // the user was not logged in on my.webinify.com
        // so you need to redirect it too
        // with login credentials
        this.redirectToLogin();
      }
    } 
  }

  static isCurrentAppIdentityProvider() {
   return REACT_APP_NAME === Constants.domains.account; 
  }

  static isAppToReturnIDP(name) {
    return name === Constants.domains.account; 
  }

  /**
     * This funciton will generate a new token, refresh_token
     * and redirect to the right app with those tokens
     * no need to store those tokens in the IdentityProvider App
     */
  async generateAndRedirect(authTokens) {
   

    // get the app
    let currentUrl = new URL(window.location.href);
    const appName = currentUrl.searchParams.get("return_to");

    let app = await WebinifyApp.getApp(appName);

    // constuire l'URL avec token
    let url = new URL(app.getUrl());
    url.searchParams.set("token", authTokens.token);
    url.searchParams.set("refresh_token", authTokens.refreshToken);
    url.searchParams.set("refresh_token_expiration", authTokens.refreshTokenExpiration);
    url.searchParams.set("from", REACT_APP_NAME);

    if (currentUrl.searchParams.has('return_path'))  {
      url.searchParams.set('return_path', currentUrl.searchParams.get('return_path'));
    }

    url.pathname = AuthorizeController._PATH;

    window.location.href = url.href;
  }



  redirectToLogin() {
    // this will redirect to login page

    let currentUrl = new URL(window.location.href);
    currentUrl.pathname = '/auth/login'; // redirect to login page
    // we keep the params of the previous url

    window.location.href = currentUrl.href;

  }


  async redirectToApp(authTokens) {
    // this will redirect to app with or without path
    // with login credentials to log the app

    // get the app
    let currentUrl = new URL(window.location.href);
    const appName = currentUrl.searchParams.get("return_to");

    let app = await WebinifyApp.getApp(appName);

    // constuire l'URL avec token
    let url = new URL(app.getUrl());
    url.searchParams.set("token", authTokens.token);
    url.searchParams.set("refresh_token", authTokens.refreshToken);
    url.searchParams.set("refresh_token_expiration", authTokens.refreshTokenExpiration);
    url.searchParams.set("from", REACT_APP_NAME);

    if (currentUrl.searchParams.has('return_path'))  {
      url.searchParams.set('return_path', currentUrl.searchParams.get('return_path'));
    }

    url.pathname = AuthorizeController._PATH;

    window.location.href = url.href;
  }



  render() {
    return;
  }
}