import React from "react";
import AuthController from "../../Security/AuthController";
import AppRedirect from "../../Utility/AppRedirect";
import AvatarHeaderButtonWidget from "../AvatarHeaderButtonWidget/AvatarHeaderButtonWidget";
import loadingWidget from "../LoadingWidget";
import "./HeaderAuthenticatedButtonWidget.scss";


export default class HeaderAuthenticatedButtonWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isUserLoggedIn: undefined,
        }
    }

    componentDidMount() {
        this.isUserLoggedIn();
    }


    isUserLoggedIn = async () => {
        await AuthController.isLoggedIn().then(res => {
            this.setState({ isUserLoggedIn: res });
        });
    }

    redirectToSSOLogin = async () => {
        let loggedIn = await AuthController.autoRefresh(); // try a refresh
        this.setState({ isUserLoggedIn: loggedIn });

        if (!loggedIn) {
            // Should redirect to SSO login page
            // loggedIn = await AuthApi.login();

            await AppRedirect.toSSO();
        }


    }


    render() {

        if (this.state.isUserLoggedIn === undefined) {
            return loadingWidget.buttonLoadingSpinner(); // should show loading
        } else if (this.state.isUserLoggedIn === true) {
            return <AvatarHeaderButtonWidget onClick={AppRedirect.myAccount} />
        } else {
            return <button className="login-button" onClick={this.redirectToSSOLogin}>Loggin</button>
        }

    }
}