export default class SessionStorageUtility {

    static getCachedData(key, expirationTime) {
        const cachedData = sessionStorage.getItem(key);

        if (cachedData) {
            const { timestamp, data } = JSON.parse(cachedData);
            if (Date.now() - timestamp < expirationTime) {
                return data;
            }
        }
        return null;
    }

    static cacheData(key, data) {
        const cachedData = JSON.stringify({
            timestamp: Date.now(),
            data: data
        });
        sessionStorage.setItem(key, cachedData);

    }

    static deleteData(key) {
        sessionStorage.removeItem(key);
    }

    static clear() {
        sessionStorage.clear();
    }
}