import AuthApi from "../Api/AuthApi";
import Constants from "../Constants";
import SingleSignOnController from "../Controller/SSO/V1/IdentityProvider/SingleSignOnController";
import AuthLoginToken from "../Model/AuthLoginToken";
import UserRepository from "../Repository/UserRepository";
import WebinifyAppRepository from "../Repository/WebinifyAppRepository";


export default class AuthController {

    static isRefreshable() {
        return AuthLoginToken.isStored();
    }

    static hasStoredData() {
       return AuthLoginToken.isStored();
    }


    static async autoRefresh() {
        if(AuthController.isRefreshable()) {
            // this when it is refreshable
            console.log("auto refresh running...");
            let refresh = await AuthApi.refresh();
    
            if(!refresh) {
                // expired ?
                console.log("couldnt auto refresh");
                return false;
            }
            return true;
        }
        return false;
    }

    static async isLoggedIn() {
        if(AuthController.hasStoredData()) {
            const user = await UserRepository.getCurrentUser();
            if(user) {
                return true;
            }
        }
        return false;
    }

    /**
     * This will check if the user is logged in
     * if logged in, if will do nothing
     * if not logged in, it will redirect to login page
     */
    static async restrictedPageAccessForUsers() {
        const bool = await AuthController.isLoggedIn();

        if(bool === false) {
            // if not logged in, redirect to a page
            const apps = await WebinifyAppRepository.getAll();
            const app = apps.find(a => a.domain === Constants.domains.account);

            let url = new URL(app.getUrl());
            url.pathname = SingleSignOnController._PATH;
            url.searchParams.set("return_to", Constants.AppName); // adding without port
            url.searchParams.set("return_path", window.location.pathname); // adding without port
   
            window.location.href = url.href;
        }

        return bool;
    }


    // async onLogin()  {
    //     // chech if refreshable

    //     // if not refreshable then redirect to login SSO

    //     // if refreshable then stay on the page
    //     // get user data
    //     //const user = await UserRepository.getCurrentUser();

    // }

    // logout() {

    // }



}