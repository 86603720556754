
import User from "../Model/User";
import ApiPrefix from "../Api/ApiPrefix";
import ApiFetch from "../Api/ApiFetch";

export default class UserRepository {



  static async getCurrentUser() {
    let url = new URL(ApiPrefix.getByEnv('AUTH') + "/user/me");

    const response = await ApiFetch.authenticated(url, {
      method: "GET",
    })

    if (response && response.ok) {
      const json = await response.json();
      return new User(json);

    }
    return null;
  }

}