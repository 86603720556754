import Constants from "../Constants";
import SingleSignOnController from "../Controller/SSO/V1/IdentityProvider/SingleSignOnController";
import WebinifyAppRepository from "../Repository/WebinifyAppRepository";


export default class AppRedirect {


    static async myAccount() {
        let apps = await WebinifyAppRepository.getAll();
        let app = apps.find(app => app.domain === Constants.domains.account);
        let url = new URL(app.getUrl());
        window.location.href = url.href;
    }

    static async toSSO() {
        let apps = await WebinifyAppRepository.getAll();
        let app = apps.find(app => app.domain === Constants.domains.account);

        let url = new URL(app.getUrl());
        url.pathname = SingleSignOnController._PATH;
        url.searchParams.set("return_to", Constants.AppName); // adding without port

        window.location.href = url.href;
    }

    // static async toSSOWithCurrentPath() {
    //     let apps = await WebinifyAppRepository.getAll();
    //     let app = apps.find(app => app.domain === Constants.domains.account);

    //     let url = new URL(app.getUrl());
    //     url.pathname = SingleSignOnController._PATH;
    //     url.searchParams.set("return_to", Constants.AppName); // adding without port

    //     window.location.href = url.href;
    // }
}