import React from "react";
import DocumentMeta from "react-document-meta";
import CommonHeader from "../Widget/Header/CommonHeader";
import HeaderAuthenticatedButtonWidget from "../Widget/HeaderAuthenticatedButtonWidget/HeaderAuthenticatedButtonWidget";

export default class AnonymousController extends React.Component {


    render({
        extraActions = <>
                <HeaderAuthenticatedButtonWidget />
            </>,
        inviewToRender,
        customHeader = undefined,
        meta,
    }) {
       
        return (
            <DocumentMeta {...meta}>
                {customHeader ? customHeader : <CommonHeader showOtherActions={extraActions}/>}
                {inviewToRender}
            </DocumentMeta>
        );
      


    }
}