import React from "react";
import ThemeManager from "../../../Themes/ThemeManager";
import AvatarWidget from "../../AvatarWidget/AvatarWidget";
import "./WebinifyAppPanelButton.scss";


export default class WebinifyAppPanelButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            app: props.app,
        }

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        window.open(this.state.app.getUrl(), this.state.app.isExternal === true ? "_blank" : "_self");
    }



    render() {

        return <button onClick={this.onClick} className="WebinifyAppPanelButton">
            <div className="AppIcon">
                 <AvatarWidget image={
                        ThemeManager.getTheme() === ThemeManager.light ?
                            this.state.app.whiteIconUrl : 
                            this.state.app.darkIconUrl} />
            </div>
            <div className="AppName">
                <p>{this.state.app.name}</p>
            </div>


        </button>

    }

}