import './theme-selector.scss';

export default class ThemeManager {
    static light = 'light';
    static dark = 'dark';
    static future = 'future';

    static getTheme() {
        return localStorage.getItem('app-theme') || ThemeManager.light;
    }


    static setDashboardDark() {
        localStorage.setItem('app-dashboard-theme', ThemeManager.dark);
    } 
    
    static setDashboardLight() {
        localStorage.setItem('app-dashboard-theme', ThemeManager.light);
    }

    static setDashboardFuture() {
        localStorage.setItem('app-dashboard-theme', ThemeManager.future);
    }

    static setDark() {
        localStorage.setItem('app-theme', ThemeManager.dark);
    } 
    
    static setLight() {
        localStorage.setItem('app-theme', ThemeManager.light);
    }

    static setFuture() {
        localStorage.setItem('app-theme', ThemeManager.future);
    }
}