import React from "react";
import HeaderCircleButtonWidget from "../HeaderCircleButtonWidget/HeaderCircleButtonWidget";
import AppDrawer from "./AppDrawer/AppDrawer";
import { GrApps } from "react-icons/gr";

import "./WebinifyAppButton.scss";

export default class WebinifyAppButton extends React.Component {



    render() {

        return (
            <HeaderCircleButtonWidget
                    onClick={AppDrawer.toggleDrawer}
                    buttonContent={
                        <GrApps />
                    }
                />
        )
    }
}