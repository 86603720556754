import React from "react";
import AuthLoginToken from "../../../../Model/AuthLoginToken";

export default class AuthorizeController extends React.Component {
  static _PATH = "/auth/sso/v1/auhorize";
  constructor(props) {
    super(props);


    this.urlLoginHandler = this.urlLoginHandler.bind(this);
  }

  componentDidMount() {
    this.urlLoginHandler();
  }

  urlLoginHandler() {
    let url = new URL(window.location.href); // get the url

    if (url.searchParams.has("token") && url.searchParams.has("refresh_token")) {
      // if it has these query params in the url
      let authToken = new AuthLoginToken({token: url.searchParams.get("token"), refresh_token: url.searchParams.get("refresh_token"), refresh_token_expiration: url.searchParams.get("refresh_token_expiration")});

      // sotre them
      authToken.store();
    } 

    // then redirect to the main page
    window.location.href = url.searchParams.has("return_path") ? url.searchParams.get("return_path") : "/";
  }




    render() {
        return ;
    }
}