import React from "react";
import "./HeaderCircleButtonWidget.scss";

export default class HeaderCircleButtonWidget extends React.Component {
    constructor(props) {
        super(props);

        this.onClick = props.onClick;
        this.buttonContent = props.buttonContent;
    }


    render() {
        return <button onClick={this.onClick} className="HeaderCircleButtonWidget">
            {this.buttonContent}
        </button>
    }
}