import React from "react";
import AppAssets from "../../AppAssets";
import "./AvatarWidget.scss";


export default class AvatarWidget extends React.Component {
    constructor(props) {
        super(props);


        this.state = {
            image: props.image || AppAssets.EmptyAvatar,
        }
      
    }

    render() {
        return <div className="AvatarWidget">
            <img src={this.state.image} alt="user profile"></img>
        </div>
    }
}