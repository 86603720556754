import ObjectStorageManager from "../LocalDatabase/ObjectStorageManager";


export default class AuthLoginToken {
    static _NAME = "AuthLoginToken";

    constructor(obj) {
        this.token = obj.token || null;
        this.refreshToken = obj.refresh_token || obj.refreshToken || null;
        this.refreshTokenExpiration = obj.refresh_token_expiration || obj.refreshTokenExpiration || null;
    }

    /**
     * 
     * @returns {void}
     */
    store() {
        ObjectStorageManager.add(AuthLoginToken._NAME, this);
        // we should store this in an encrypted way 
    }

    static isStored() {
        return ObjectStorageManager.get(AuthLoginToken._NAME) ? true : false;
    }
    static clear() {
        return ObjectStorageManager.remove(AuthLoginToken._NAME);
    }

    /**
     * 
     * @returns {string|null}
     */
    getToken() {
        return this.token;
    }

    /**
     * 
     * @returns {string|null}
     */
    getRefreshToken() {
        return this.refreshToken;
    }

    getExpiry() {
        return this.refreshTokenExpiration;
    }

    static getAuthorizationBearer() {
        return "Bearer " + AuthLoginToken.fromStorage().getToken();
    }

    /**
     * 
     * @returns {AuthLoginToken|null}
     */
    static fromStorage() {
        if (ObjectStorageManager.get(AuthLoginToken._NAME)) {
            return new AuthLoginToken(ObjectStorageManager.get(AuthLoginToken._NAME));
        }
        return new AuthLoginToken({});
    }

}