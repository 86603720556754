import React from "react";
import appExtendedLogo from "./../../Image/webinify-logo.png";
import appIconLogo from "./../../Icon/favicon.png";
import "./AppLogo.scss";

export default class AppLogo extends React.Component {
    // constructor() {
    //     super();

    //     this.bindEvents();
    // }

    // bindEvents() {
    //     setTimeout(() => {
    //         this.onPageLoaded();
    //     }, 1000);

    // }

    onPageLoaded() {
        document.querySelectorAll('.delayed-logo-display').forEach(el => {
            el.setAttribute('src', el.getAttribute("src-data"));
        });
    }

    onClick() {
        window.location.href = '/';
    }


    render() {
        return  <div onClick={this.onClick} className="appLogo">
        <img className="desktop tablet" src={appExtendedLogo} alt="Webinify logo"></img>
        <img className="mobile" src={appIconLogo} alt="Webinify logo"></img>
    </div>
    }
}