import WebinifyAppRepository from "../Repository/WebinifyAppRepository";


export default class WebinifyApp  {
  static _NAME = "WebinifyApp";

  constructor(object) {

    this.id = object.id;
    this.devUrl = object.devUrl;
    this.prodUrl = object.prodUrl;
    this.localUrl = object.localUrl;
    this.name = object.name;
    this.faIcon = object.faIcon;
    this.domain = object.domain;
    this.darkIconUrl = object.darkIconUrl;
    this.whiteIconUrl = object.whiteIconUrl;
    this.iconUrl = object.iconUrl;
    this.isExternal = object.isExternal;
  }



  /**
     *
     * @param {Array} objectList
     * @returns {WebinifyApp[]}
     */
  static fromList(objectList = []) {
    if (objectList.length === 0) 
      return objectList;
    return objectList.map(o => new WebinifyApp(o));
  }

  /**
     *
     * @returns {Promise<WebinifyApp[]>}
     */
  static async getAllApps() {
    return await WebinifyAppRepository.getAll(performance.navigation.type === performance.navigation.TYPE_RELOAD);
  }

  static async getApp(appName) {
    const apps = await WebinifyApp.getAllApps();
    return apps.find(app => app.domain === appName);
  }
  
  getUrl() {
    const hostname = window.location.hostname;
    const hostArray = hostname.split(".");
    const len = hostArray.length;
    const isDomain = len === 1
      ? false
      : true;

    const tld = hostArray[len - 1];
    if (isDomain) {
      if (tld === "com") {
        return this.prodUrl;
      } else if (tld === "dev") {
        return this.devUrl;
      }
    }
    return this.localUrl;
  }


  redirect() {
       // constuire l'URL avec token
       let url = new URL(this.getUrl());
       window.location.href = url.href;
  }
}