import React from "react";
import AppAssets from "../../AppAssets";
import AvatarWidget from "../AvatarWidget/AvatarWidget";
import HeaderCircleButtonWidget from "../HeaderCircleButtonWidget/HeaderCircleButtonWidget";
import "./AvatarHeaderButtonWidget.scss";


export default class AvatarHeaderButtonWidget extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            image: props.image || AppAssets.AppButtonDrawerIcon
        }


    }


    onClick(){
        console.log('clicked on profile pic, you need to open a menu');
    }


    render() {
        return <HeaderCircleButtonWidget 
                    onClick={this.props.onClick ?? this.onClick} 
                    buttonContent={<AvatarWidget image={this.image}></AvatarWidget>}
                />
    }
}