import React from "react";
import WebinifyApp from "../../../Model/WebinifyApp";
import WebinifyAppPanelButton from "../WebinifyApp/WebinifyAppPanelButton";
import './AppDrawer.scss';

export default class AppDrawer extends React.Component {
    constructor(props) {
        super(props);


        this.state = {
            apps:[]
        };
    }


    componentDidMount() {
        this.getApps();
    }

    async getApps() {
        const apps = await WebinifyApp.getAllApps();


        if(apps) {
            this.setState({apps: apps});
        }

    }

    static openDrawer() {
        if(document.querySelector('.AppDrawer')) {
            document.querySelector('.AppDrawer').classList.add('show');
        }
    }

    static closeDrawer() {
        if(document.querySelector('.AppDrawer')) {
            document.querySelector('.AppDrawer').classList.remove('show');
        }
        
    }

    static toggleDrawer() {
        if(document.querySelector('.AppDrawer')) {
            document.querySelector('.AppDrawer').classList.toggle('show');
        }
    }

    render() {
       let appList = this.state.apps.map(app => <WebinifyAppPanelButton key={app.id} app={app}></WebinifyAppPanelButton>);

       if(appList) {
        return <div className="AppDrawer">
                <div className="AppList">
                    {appList}
                </div>
            </div>
       }
     
    }
}