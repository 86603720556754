import { TailSpin } from "react-loader-spinner";
import Constants from "../Constants";

export default class loadingWidget {


    static buttonLoadingSpinner() {
        return <TailSpin
        height="40"
        width="40"
        color={Constants.BrandColor}
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    }
}